import React from 'react'
import { Link } from 'gatsby'
import {
  faMobile,
  faFileAlt,
  faPhone,
  faCrown,
  faHandshake,
  faGraduationCap,
  faMoneyCheck,
  faFileContract,
  faPoundSign,
  faFlask,
  faSack,
  faShieldAlt,
} from '@fortawesome/pro-light-svg-icons'

import callImg from '../../images/svg/how-it-works/call.svg'
import webChatImg from '../../images/svg/how-it-works/web-chat.svg'
import accountImg from '../../images/svg/how-it-works/account.svg'
import standalone from '../../images/svg/how-it-works/standalone.svg'
import specialised from '../../images/svg/how-it-works/specialised.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import TimelineStep from '../../components/Timeline'
import SquircleIcon from '../../components/SquircleIcon'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { Intro, IntroTitle, IntroText } from '../../components/Intro'
import { Items, Item, ItemTitle } from '../../components/Items'
import { CtaCurve, CtaCurveTitle } from '../../components/CtaCurve'
import { PricingPlanTable, PricingPlan } from '../../components/PricingPlan'
import {
  CircleIconGroup,
  CircleIconGroupTitle,
} from '../../components/CircleIcon'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../../components/SideBySide'

const SwitchingAccountants = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Start up accountants" />
      <TitleContainer>
        <Title>Switching accountants</Title>
        <TitleIntro>
          Our accounting service is built to re-invent what an accountant should
          be. With easy-to-use apps, competitive fixed monthly fees and
          unlimited advice, it's not difficult to see why small business owners
          are choosing AirAccounting over their traditional accountant.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <Intro>
          <IntroTitle>How does it work?</IntroTitle>
          <IntroText>
            We make switching accountants as smooth as possible. Our team will
            handle all the heavy lifting keep you up-to-date at every stage.
          </IntroText>
        </Intro>
        <TimelineStep
          img={webChatImg}
          imgAlt="Contact us"
          count="01"
          heading="Speak to our team"
          lineEnd
        >
          <p>
            Speak to our friendly team to discuss your situation. We want to
            understand your business and the best way to approach your
            transition to us. We'll also help you decide which services you need
            from us as your new accountants.
          </p>
        </TimelineStep>
        <TimelineStep
          img={callImg}
          imgAlt="Switching accountant"
          heading="Notify your current accountant"
          count="02"
          lineStart
          lineEnd
          reverse
        >
          <p>
            Let your current accountant know that you're moving. Our team will
            then write to your previous advisors asking for any information we
            may need. We typically receive a response to our request within 1-2
            weeks.
          </p>
        </TimelineStep>
        <TimelineStep
          img={accountImg}
          imgAlt="Finish setup"
          count="03"
          heading="Complete setup"
          lineStart
        >
          <p>
            We'll setup your software account ready for us to start delivering
            your services. Our team will begin offering advice and answering any
            techinical questions you may have at this point. We'll also get
            ourselves authorised as your tax agent with HMRC.
          </p>
        </TimelineStep>
      </Section>
      <Section color="gray" bubbles>
        <Intro>
          <IntroTitle>What is your pricing?</IntroTitle>
          <IntroText>
            We offer an amazing level of support when you sign up to our small
            business accounting service, with various add-ons you can choose to
            sign up to should you require them.
          </IntroText>
        </Intro>
        <PricingPlanTable>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Sole trader"
            planPrice="£25.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Sole trader accounts',
              'Self-assessment tax return',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Limited company"
            planPrice="£79.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Limited company setup',
              'Limited company accounts',
              'Corporation Tax return',
              'Payroll for 2 directors',
              'Tax return for 2 directors',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
              'Company secretarial',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Partnership"
            planPrice="£48.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Partnership accounts',
              'Tax return for 2 partners',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
        </PricingPlanTable>
      </Section>
      <Section>
        <Intro>
          <IntroTitle>What's included?</IntroTitle>
          <IntroText>
            Every business owner needs help in the early stages, which is why as
            when you switch accountants to us we give you all of the below
            services as standard when you sign up to our small business
            accounting service.
          </IntroText>
        </Intro>
        <Items>
          <Item>
            <SquircleIcon color="purple" icon={faMobile} />
            <ItemTitle>Market-leading software</ItemTitle>
            <p>
              Amazing software that handles VAT returns, invoicing, expenses,
              bank statements and any other aspect of your accounts. Your
              dedicated accountant will use this software to deliver a
              personalised service for you and your business.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="green" icon={faFileAlt} />
            <ItemTitle>Tax registrations</ItemTitle>
            <p>
              It can be difficult to know what taxes apply to your business.
              With taxes such as VAT, PAYE and Corporation Tax all having their
              own rules it's easy to make a mistake. Our small business
              accountants ensure you're registered for all taxes necessary.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="orange" icon={faPhone} />
            <ItemTitle>Unlimited advice</ItemTitle>
            <p>
              Our team are always on hand to answer any questions you may have
              and to guide you in the right direction. We'll tell you what
              expenses you can claim for, what you can do to reduce your tax
              bills, how much money you're making and so much more.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="yellow" icon={faCrown} />
            <ItemTitle>HMRC representation</ItemTitle>
            <p>
              Dealing with HMRC can be intimidating and stressful. Our experts
              handle all contact with HMRC on your behalf by approaching each
              scenario with great care and ensuring your taxpayer rights are
              upheld.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="pink" icon={faHandshake} />
            <ItemTitle>Partner network</ItemTitle>
            <p>
              We've partnered with some truly great businesses to help our
              clients get the support and services they need. From bank accounts
              to insurance, business loans to legal help, you have access to
              them all.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="blue" icon={faGraduationCap} />
            <ItemTitle>Training</ItemTitle>
            <p>
              Keeping an eye on the numbers is a key ingredient to success. Our
              small business accountants will work with you to help you
              understand your business's performance and other useful
              information.
            </p>
          </Item>
        </Items>
      </Section>
      <Section color="gray">
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Don't need the full service?</SideBySideTitle>
            <p>
              We offer standalone services for those business owners that just
              need a little helping hand.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faMoneyCheck} color="primary">
                <CircleIconGroupTitle>Payroll & pension</CircleIconGroupTitle>
                <p>
                  Taxes and deductions calculated and payslips prepared for your
                  staff.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faFileContract} color="orange">
                <CircleIconGroupTitle>
                  Catch up year end accounts
                </CircleIconGroupTitle>
                <p>
                  Preparing accounts to meet deadlines and to get your books
                  back up-to-date.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faPoundSign} color="green">
                <CircleIconGroupTitle>
                  Personal tax returns
                </CircleIconGroupTitle>
                <p>
                  Completing and submitting your personal tax return to report
                  your income and capital gains.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-primary-light"
              to="/pricing/standalone-services"
            >
              Learn more
            </Link>
          </SideBySideText>
          <SideBySideImg src={standalone} alt="standalone services" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={specialised} alt="specialised services" />
          <SideBySideText>
            <SideBySideTitle>Need expert help?</SideBySideTitle>
            <p>
              Our specialised services carried out by experienced professionals
              can make a real impact to you and your business.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faFlask} color="primary">
                <CircleIconGroupTitle>
                  Research and Development (R&D)
                </CircleIconGroupTitle>
                <p>
                  Assessing, preparing and submitting detailed R&D tax credit
                  claims on a no-win no-fee basis.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faSack} color="orange">
                <CircleIconGroupTitle>Fundraising</CircleIconGroupTitle>
                <p>
                  Through our network of partners we can help raise funds for
                  your business to grow or ease cashflow.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faShieldAlt} color="green">
                <CircleIconGroupTitle>Tax investigatons</CircleIconGroupTitle>
                <p>
                  We can take full control of a tax investigation to minimise
                  the risk of big bills.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-green-light"
              to="/pricing/standalone-services"
            >
              Get in touch
            </Link>
          </SideBySideText>
        </SideBySide>
      </Section>
      <CtaCurve>
        <CtaCurveTitle>Ready to switch accountants?</CtaCurveTitle>
        <p>We can call you anytime between 9am - 5pm Monday to Friday.</p>
        <Link className="btn btn-primary-invert" to="/call-back">
          Request a call back
        </Link>
      </CtaCurve>
    </SiteLayout>
  )
}

export default SwitchingAccountants
